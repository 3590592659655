import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function MoreDetailsButton() {
  // return (
  //   <div className="vb-text-orange font-size-15px d-flex align-items-center" role="button">
  //       More <ArrowForwardIosIcon sx={{fontSize:15}} />
  //   </div>
  // )
  return <></>
}
