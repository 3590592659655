import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import ExpandableView from '../ExpandableView';
import moment from 'moment'
import { CLUB_DETAILED_BET_HISTORY } from '../../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../../API-Handle/services/auth_curd';
import { useLocation } from 'react-router-dom'
import { APP_OFFSET } from '../../../../../constant';
import Spinner from '../../../../../Components/CustLoader/Spinner';
import DeleteBet from '../BetActions/DeleteBet';

const columns = [
    {
        name: 'Team',
        selector: row => row.team,
        sortable: true,
        minWidth: "240px"
    },
    {
        name: 'Risk',
        selector: row => row.risking.toFixed(2),
        sortable: true
    },
    {
        name: 'To Win',
        selector: row => row.winnings.toFixed(2),
        sortable: true
    },
    {
        name: 'Bet Type',
        selector: row => row.bet_type,
        sortable: true
    },
    {
        name: 'Placed Date',
        selector: row => row.final_date,
        sortable: true,
        minWidth: "230px"
    },
    {
        name: 'Action',
        cell: row => <DeleteBet data={row} />,
        sortable: true,
        width: "100px"
    }
];

export default function ExpView({ data }) {
    const [preparedData, setPreparedData] = useState(null)
    const location = useLocation()

    const getBets = async () => {
        setPreparedData(null)
        let club_id = location?.state?.club?.id;
        if (!club_id) return;

        let request_url = CLUB_DETAILED_BET_HISTORY + club_id + `?bet_type=UNSETTLED&player_id=${data.user_id}`;
        let bets = await getValuebet(request_url);
        prepareData(bets);
    }

    const prepareData = async (bets) => {
        if (bets) {
            for (let row of bets) {
                if (row.bet_type === "Straight") {
                    let temp_target = row.bets[0].bet.targeted_team
                    if (row.bets[0].bet.market_name === 'moneyline') {
                        let temp_team = `${temp_target} - ML`
                        if (temp_team.length > 28) {
                            let removable = temp_target.length - ((temp_team.length - 28) + 3);
                            temp_target = temp_target.substring(0, removable) + "..."
                        }
                        row.team = `${temp_target} - ML`
                    }
                    if (row.bets[0].bet.market_name === 'spread') {
                        let temp_team = `${temp_target} ${row.bets[0].bet.selected_odd_points > 0 ? "+" + row.bets[0].bet.selected_odd_points : row.bets[0].bet.selected_odd_points} - SP`
                        if (temp_team.length > 28) {
                            let removable = temp_target.length - ((temp_team.length - 28) + 3);
                            temp_target = temp_target.substring(0, removable) + "..."
                        }
                        row.team = `${temp_target} ${row.bets[0].bet.selected_odd_points > 0 ? "+" + row.bets[0].bet.selected_odd_points : row.bets[0].bet.selected_odd_points} - SP`
                    }
                    if (row.bets[0].bet.market_name === 'total') {
                        let odd_name = '';
                        if (row.bets[0].bet.selected_odd_name === "OVER") {
                            odd_name = "O";
                        } else if (row.bets[0].bet.selected_odd_name === "UNDER") {
                            odd_name = "U";
                        }

                        let temp_team = `${temp_target} ${odd_name} ${row.bets[0].bet.selected_odd_points} - T`
                        if (temp_team.length > 28) {
                            let removable = temp_target.length - ((temp_team.length - 28) + 3);
                            temp_target = temp_target.substring(0, removable) + "..."
                        }
                        row.team = `${temp_target} ${odd_name} ${row.bets[0].bet.selected_odd_points} - T`
                    }
                    if (row.bets[0].bet.period_name === 'period_live_full_game') {
                        row.team = <span>{row.team} <span className='text-danger'>●</span></span>
                    }
                } else {
                    row.team = "Multi-Teams"
                }
                row.final_date = moment.utc(row.created_at).utcOffset(APP_OFFSET).format('L LT') + " PST"
            }
            setPreparedData(bets)
        }
    }

    useEffect(() => {
        getBets()
        // eslint-disable-next-line
    }, [])

    return (
        <div className='px-4 py-2 head-yellow responsive-expand'>
            {preparedData ?
                <DataTable
                    columns={columns}
                    data={preparedData}
                    expandableRows
                    expandableRowsComponent={<ExpandableView />}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                    footer={undefined}
                /> :
                <Spinner />}
        </div>
    )
}
