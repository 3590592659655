import React, { useContext, useEffect, useState } from 'react'
import CustomDropdown from '../../../../../../Components/CustInputs/CustomDropdown';
import LockOutlined from '@mui/icons-material/LockOutlined';
import { LINES } from '../../../../../../constant';
import { postValuebet, CHANGE_SPORTSBOOK_URL } from '../../../../../../API-Handle/services/auth_curd';
import { errorNotification, successNotification } from '../../../../../../Components/CustComp/PPHAlert';
import ValuebetContext from '../../../../../../context/Valuebet/ValuebetContext';

export default function LineSelector({ clubInfo }) {
    const [value, setValue] = useState({});
    const { fetchSingleClub, resetSportBook } = useContext(ValuebetContext);

    useEffect(()=>{
        let val = {}
        LINES.forEach((item)=>{
            if (item.value === clubInfo.sportsbook){
                val = item;
            }
        })
        setValue(val)
    }, [clubInfo])

    const changeLine = async (e) => {
        if(e?.value){
            let data = {
                "club_id": clubInfo.id,
                "sportsbook": e.value
            }
            let res = await postValuebet(`${CHANGE_SPORTSBOOK_URL}`, data)
            if (res) {
                successNotification(res.message);
                resetSportBook();
                fetchSingleClub(clubInfo.id)
            }
        }
    }

    const wantMessage = () => {
        if (!clubInfo.level_details.odds_selection){
            errorNotification("Upgrade Club to Unlock!")
        }
    }

    return (
        <span className='d-flex align-items-center' onClick={wantMessage}>
            <CustomDropdown
                placeholder="Select Sportsbook"
                options={LINES}
                value={value}
                onChange={changeLine}
                isDisabled={!clubInfo.level_details.odds_selection}
            /> {clubInfo.level_details.odds_selection ? "" : <LockOutlined className='icon-bet-no' />}
        </span>
    )
}
