import { OFFLINE_ODD } from "../constant";

// Getting sport limits
export function getSportLimits(sportsData, leagueName, limitsType) {
  const leagues = sportsData? sportsData.reduce((acc, sport) => acc.concat(sport.leagues), []) : [];
  for (const league of leagues) {
      if (league.league_name === leagueName) {
          return league[limitsType];
      }
  }
  return null;
};

// Calculating Multi-Bet Decimal Odd using multipule american odds
export function getMultiDecimalOdd(bets) {
  let decimal_odd = 1;
  for (let bet of bets) {
      if (bet.odd_money !== OFFLINE_ODD) {
          if (bet.odd_money < 0) {
              decimal_odd *= (1 - (100 / bet.odd_money));
          } else if (bet.odd_money > 0) {
              decimal_odd *= (1 + (bet.odd_money / 100));
          }
      } else {
          return OFFLINE_ODD;
      }
  }
  return decimal_odd;
}

export function AmrcToDec(odd_money) {
  let decimal_odd = 1;
  if (odd_money < 0) {
    decimal_odd *= (1 - (100 / odd_money));
  } else if (odd_money > 0) {
    decimal_odd *= (1 + (odd_money / 100));
  }
  return decimal_odd;
}

// Soccer/Hockey: Favorite Spreads cannot be combined with Over; Underdog Spreads cannot be combined with Under
export function soccer_hockey_restrictions(bets) {
  let favorite_spreads = false;
  let underdog_spreads = false;
  let total_over = false;
  let total_under = false;

  for (let bet of bets) {
    if (bet['market_name'] === "Spread") {
      if (bet['odd_points'] <= 0) favorite_spreads = true;
      else underdog_spreads = true;
    }
    if (bet['market_name'] === "Total") {
      if (bet['odd_name'] === "OVER") total_over = true;
      else if (bet['odd_name'] === "UNDER") total_under = true;
    }
  }

  if ((favorite_spreads && total_over) || (underdog_spreads && total_under)) return true;
  return false;
}


// Football: For Restrict 1
// Favorites with Spread -13 or more cannot be combined with Over 42 or less; 
// Underdog with Spread +13 or more cannot be combined with Under 42 or less.
// --------------------------------------------------------------------------
// Football: For Restrict 2
// If Total is not greater than 2x Spread; do not allow (-Spread + Over) and (+Spread + Under)
export function football_restrictions(bets) {
  // For Restrict 1
  let favorite_spreads = false;
  let underdog_spreads = false;
  let total_over = false;
  let total_under = false;

  // For Restrict 2
  let favorite_spreads_points = null;
  let underdog_spreads_points = null;
  let total_over_points = null;
  let total_under_points = null;

  for (let bet of bets) {
    if (bet['market_name'] === "Spread") {
      // For Restrict 1
      if (bet['odd_points'] <= -13) favorite_spreads = true;
      else if (bet['odd_points'] >= 13) underdog_spreads = true;

      // For Restrict 2
      if (bet['odd_points'] < 0) favorite_spreads_points = bet['odd_points'];
      else if (bet['odd_points'] > 0) underdog_spreads_points = bet['odd_points'];
    }

    if (bet['market_name'] === "Total") {
      // For Restrict 1
      if ((bet['odd_name'] === "OVER") && (bet['odd_points'] <= 42)) total_over = true;
      else if ((bet['odd_name'] === "UNDER") && (bet['odd_points'] <= 42)) total_under = true;

      // For Restrict 2
      if (bet['odd_name'] === "OVER") total_over_points = bet['odd_points'];
      else if (bet['odd_name'] === "UNDER") total_under_points = bet['odd_points'];
    }
  }

  // For Restrict 1
  if ((favorite_spreads && total_over) || (underdog_spreads && total_under)) return true;

  // For Restrict 2
  if (favorite_spreads_points && total_over_points) {
    if (!((-2 * favorite_spreads_points) < total_over_points)) return true;
  }
  if (underdog_spreads_points && total_under_points) {
    if (!((2 * underdog_spreads_points) < total_under_points)) return true;
  }

  return false;
}


// Baseball: Overs of 7 or less cannot be combined with Favorite Spreads; 
// Unders of 7 or less cannot be combined with Underdog Spreads.
export function baseball_restrictions(bets) {
  let favorite_spreads = false;
  let underdog_spreads = false;
  let total_over = false;
  let total_under = false;

  for (let bet of bets) {
    if (bet['market_name'] === "Spread") {
      if (bet['odd_points'] <= 0) favorite_spreads = true;
      else underdog_spreads = true;
    }
    if (bet['market_name'] === "Total") {
      if ((bet['odd_name'] === "OVER") && (bet['odd_points'] <= 7)) total_over = true;
      else if ((bet['odd_name'] === "UNDER") && (bet['odd_points'] <= 7)) total_under = true;
    }
  }
  if ((favorite_spreads && total_over) || (underdog_spreads && total_under)) return true
  return false;
}

// All sports: Spread cannot be combined with Moneyline on the same team
export function all_game_restrictions(bets) {
  let spread_home = false;
  let spread_away = false;
  let moneline_home = false;
  let moneline_away = false;
  // let moneline_draw = false;
  // let total_over = false;
  // let total_under = false;
  for (let bet of bets) {
    if (bet['market_name'] === "Spread") {
      if (bet['odd_name'] === "HOME") spread_home = true;
      else if (bet['odd_name'] === "AWAY") spread_away = true;
    }
    if (bet['market_name'] === "Moneyline") {
      if (bet['odd_name'] === "HOME") moneline_home = true;
      else if (bet['odd_name'] === "AWAY") moneline_away = true;
      // else if (bet['odd_name'] === "DRAW") moneline_draw = true;
    }
    // if (bet['market_name'] === "Total"){
    //   if (bet['odd_name'] === "OVER") total_over = true;
    //   else if (bet['odd_name'] === "UNDER") total_under = true;
    // }
  }
  if ((spread_home && moneline_home) || (spread_away && moneline_away)) return true
  // if ((spread_home && spread_away) || ((moneline_home && moneline_away) || (moneline_away && moneline_draw) || (moneline_home && moneline_draw)) || (total_over && total_under)) return true;
  return false;
}


// To get readble period using period id
export const getReadablePeriod = (period) => {
  const periods = {
    period_full_game: "Game",
    period_live_full_game: "Game(Live)",
    period_first_half: "1st Half",
    period_second_half: "2nd Half",
    period_first_period: "1st Period",

  }
  return periods[period]
}