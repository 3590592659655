import React, { useEffect, useState } from 'react'
import { GET_CLUB_BET_LIMITS_URL, UPDATE_CLUB_BET_LIMIT_URL, getValuebet, postValuebet } from '../../../../../../API-Handle/services/auth_curd'
import { errorNotification, successNotification } from '../../../../../../Components/CustComp/PPHAlert'
import LimitTable from '../Comman/LimitTable'

export default function Index({ club, toggleClubSetting }) {
    const [clubBetLimits, setClubBetLimits] = useState([])
    const [loader, setLoader] = useState(false)
    
    const getClubBetLimits = async() => {
        let res = await getValuebet(GET_CLUB_BET_LIMITS_URL+club.id);
        if (res) {
            let club_bet_limits = [];
            Object.keys(res).forEach(element => {
                club_bet_limits.push(res[element]);
            });
            setClubBetLimits(club_bet_limits)
        }
    }

    useEffect(() => {
        getClubBetLimits();
        // eslint-disable-next-line
    }, [])

    const saveClubBetLimits = async () => {
        setLoader(true)
        let is_error = false;
        clubBetLimits.forEach((item) => {
            if (item.max_bet_all === "" || item.max_bet_all < 0 ||
                item.max_bet_live === "" || item.max_bet_live < 0 ||
                item.max_parlay_all === "" || item.max_parlay_all < 0 ||
                item.max_parlay_live === "" || item.max_parlay_live < 0 ||
                item.max_parlay_payout === "" || item.max_parlay_payout < 0) {
                is_error = true;
            }
        })

        if (is_error) {
            setLoader(false);
            errorNotification("Please enter valid inputs. Inputs are should not be less-than 0.");
            return;
        }

        let data = {
            "club_id": club.id,
            "bet_limits": clubBetLimits
        }
        let res = await postValuebet(`${UPDATE_CLUB_BET_LIMIT_URL}`, data)
        if (res) {
            successNotification(res.message);
        }
        setLoader(false)
    }


    return (
        <div className="tab-content">
            <div className="tab-pane fade active show" id="club-bet-limits">
                {!club?.level_details?.sport_limits && <div className='text-warning py-1 px-2 mb-1 bg-dark rounded fw-bold'>Upgrade Club for Access</div>}
                <LimitTable
                    height={club?.level_details?.sport_limits ? 280 : 320}
                    loader={loader}
                    limits={clubBetLimits}
                    setUpdatedLimits={setClubBetLimits}
                    isDisable={!club?.level_details?.sport_limits}
                    saveLimits={saveClubBetLimits}
                    toggleModal={toggleClubSetting}
                />
            </div>
        </div>
    )
}
