import moment from "moment";
import ChatReact from "./BubbleComponents/ChatReact";
import Reactions from "./BubbleComponents/Reactions";
import MessageOptions from "./BubbleComponents/MessageOptions";
import ImageView from "./BubbleComponents/ImageView";
import { BASEURL } from "../../../../API-Handle/config";
import { KeyboardArrowDown } from "@mui/icons-material";
import Linkify from 'linkify-react';

function RightBubble({
  msg,
  handleClickMessage,
  handleClickInsideMessage,
  isEmojiPanelOpen,
  messageOptionsRef,
  emojiPanelPosition,
  handleMessageReply,
  handleMessageCopy,
  onReactSend,
  handleUnsendMessage,
  renderReplyMessage
}) {
  return (
    <div className="message receiver-message my-1" time={msg.time_stamp}>
      <div
        id={msg.id}
        className="receiver-message-bubble font-size-14px p-2 my-2 w-fit position-relative"
        tabIndex="0"
      >
        <div className="bubble-header">
          <span className="font-size-10px align-items-center text-light text-nowrap">
            {moment(msg.time_stamp).format("hh:mm A")}
          </span>
          <KeyboardArrowDown
            role="button"
            onClick={(event) => handleClickMessage(event, msg)}
          />
        </div>
        <div className="d-flex justify-content-between align-items-start">
          <div className="flex-grow-1">
            {msg.reply_to && (
              <div
                role="button"
                onClick={() => handleClickInsideMessage(msg.reply_to.id)}
                className="reply-sender-container mb-2"
              >
                <div className="reply-sender-name" >
                  {msg.reply_to["sender_name"]}
                </div>
                <div>{renderReplyMessage(msg)}</div>
              </div>
            )}
            {msg?.media_type === "PHOTO" && (
              <ImageView imageURL={BASEURL + msg?.media_url} />
            )}
            <div style={{ maxWidth: msg?.media_type === "PHOTO" ? "250px" : "auto" }}>
              <Linkify options={{
                attributes: {
                  rel: 'noopener noreferrer',
                  target: '_blank'
                }
              }}>
                {msg.message}
              </Linkify>
            </div>
          </div>
        </div>
        <Reactions msg={msg} onReactSend={onReactSend} isLeft={false} />
      </div>
      {isEmojiPanelOpen && (
        <div
          ref={messageOptionsRef}
          style={{
            position: "absolute",
            top: `${emojiPanelPosition.top}px`,
            left: `${emojiPanelPosition.right - 300}px`,
            width: "100px",
            height: "50px",
            zIndex: 1000,
          }}
        >
          <ChatReact onReactSend={onReactSend} />
          <MessageOptions
            isRight={true}
            msg={msg}
            handleMessageReply={handleMessageReply}
            handleMessageCopy={handleMessageCopy}
            handleUnsendMessage={handleUnsendMessage}
          />
        </div>
      )}
    </div>
  );
}

export default RightBubble;
