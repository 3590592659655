import React, { useContext, useEffect, useRef } from 'react'
import GroupWorkOutlined from '@mui/icons-material/GroupWorkTwoTone';
import BetOption from './Odds/BetOption';
import { RUNNING_STATUS } from '../../../../constant';
import TeaserBetOption from './Odds/TeaserBetOption';
import ValuebetContext from '../../../../context/Valuebet/ValuebetContext';
import MoreDetailsButton from './MoreDetailsButton';

function TeamCard({ sport, league_id, match_id, score, match_status, team_code, team_name, team_type, odds_visibility, line, period, moneyline, spread, total }) {
    const scoreTimer = useRef(null);
    const preScore = useRef(null);
    const { betDetails } = useContext(ValuebetContext);    
    const highlighterTool = () => {
        if (preScore.current !== score) {
            try {
                if (scoreTimer.current) {
                    clearTimeout(scoreTimer.current)
                }
                let ele = document.getElementById(`${team_type}${match_id}`);
                if (ele) {
                    ele.classList.add('flash-orange');
                }
                scoreTimer.current = setTimeout(() => {
                    if (ele) {
                        ele.classList.remove('flash-orange')
                    }
                }, 6000)
            } catch (err) {
                console.log(err)
            }
        }
    }

    useEffect(() => {
        highlighterTool();
        return (() => {
            preScore.current = score;
        })
    // eslint-disable-next-line
    }, [score])

    const BetComponent = odds_visibility && betDetails?.bet_info?.bet_type === "Teaser" ? TeaserBetOption : BetOption;
    const commonProps = {team_type, league_id, match_id, team_code, team_name, line, period };

    return (
        <div className={`row justify-content-between mt-2`}>
            <div className="team-responsive d-flex justify-content-between align-items-center">
                <div className={`d-flex align-items-center`}>
                    {/* <img src={imglink} alt='team-logo' className='team-logo' /> */}
                    <GroupWorkOutlined />
                    <strong className='ms-2 team-name pe-1'>{team_name}</strong>
                </div>
                {RUNNING_STATUS.includes(match_status) ? <div className={'match-info'} id={`${team_type}${match_id}`}>{score}</div>:
                (team_type === "AWAY" && window.innerWidth < 600) && <MoreDetailsButton />}
                </div>
            <div className="odds-responsive bet-option-grid">
                <BetComponent
                    value={spread}
                    market_type={"spread"}
                    {...commonProps}
                />
                <BetComponent
                    value={total}
                    market_type={"total"}
                    {...commonProps}
                />
                <BetComponent
                    value={moneyline}
                    market_type={"moneyline"}
                    {...commonProps}
                />
            </div>
        </div>
    )
}

export default TeamCard