import React, { useEffect, useState } from "react";
import logoag from "../../../../Static/UserManagement/images/common/default-user-avtar.png";
import moment from "moment";
import { BASEURL } from "../../../../API-Handle/config";
import ChatReact from "./BubbleComponents/ChatReact";
import { useSelector } from "react-redux";
import Reactions from "./BubbleComponents/Reactions";
import ImageView from "./BubbleComponents/ImageView";
import MessageOptions from "./BubbleComponents/MessageOptions";
import { KeyboardArrowDown } from "@mui/icons-material";
import Linkify from 'linkify-react';

function LeftBubble({
  msg,
  handleClickMessage,
  handleClickInsideMessage,
  isEmojiPanelOpen,
  messageOptionsRef,
  emojiPanelPosition,
  handleMessageReply,
  handleMessageCopy,
  onReactSend,
  renderReplyMessage
}) {
  const { activeChannel } = useSelector((state) => state.clubChat);
  const [leftPosition, setLeftPosition] = useState(200);
  const getLeftPosition = () => {
    if (emojiPanelPosition.left > 300 && emojiPanelPosition.left < 700) {
      setLeftPosition(340);
    }
    else if (emojiPanelPosition.left > 700) {

      setLeftPosition(emojiPanelPosition.left - 400);
    }
    else if (emojiPanelPosition.left < 150) {
      setLeftPosition(50);
    }
    else {
      setLeftPosition(100);
    }

  }
  useEffect(() => {
    getLeftPosition();
    // eslint-disable-next-line 
  }, [emojiPanelPosition])

  return (
    <div className="message my-2" time={msg.time_stamp}>
      {!activeChannel.detail && (
        <img
          src={
            msg.profile_picture
              ? `${BASEURL}/media/${msg.profile_picture}`
              : logoag
          }
          alt={msg.sender_name}
          className="avatar"
        />
      )}
      <div className="d-block sender-message">
        {!activeChannel.detail && (
          <strong className="font-size-14px text-light">
            {msg.sender_name}
          </strong>
        )}
        <div className="sender-message-content">
          <div
            className="sender-message-bubble font-size-14px p-2 my-2 w-fit position-relative"
            id={msg.id}
            tabIndex="0"
          >
            <div className="bubble-header">
              <span className="font-size-10px align-items-center text-light text-nowrap">
                {moment(msg.time_stamp).format("hh:mm A")}
              </span>
              <KeyboardArrowDown
                role="button"
                onClick={(event) => handleClickMessage(event, msg)}
              />
            </div>
            <div className="d-flex justify-content-between align-items-start">
              <div className="flex-grow-1">
                {msg.reply_to && (
                  <div
                    className="reply-recieve-container mb-2"
                    role="button"
                    onClick={() => handleClickInsideMessage(msg.reply_to.id)}
                  >
                    <div className="reply-reciever-name">
                      {msg.reply_to["sender_name"]}
                    </div>
                    <div>{renderReplyMessage(msg)}</div>
                  </div>
                )}
                {msg?.media_type === "PHOTO" &&
                  <ImageView imageURL={msg?.media_url !== null ? BASEURL + msg?.media_url : null} />
                }
                <div style={{ maxWidth: msg?.media_type === "PHOTO" ? "250px" : "auto" }}>
                  <Linkify options={{
                    attributes: {
                      rel: 'noopener noreferrer',
                      target: '_blank'
                    }
                  }}>
                    {msg.message}
                  </Linkify>
                </div>
              </div>
            </div>
              <Reactions msg={msg} onReactSend={onReactSend} isLeft={true} />
          </div>
        </div>
        {isEmojiPanelOpen && (
          <div
            ref={messageOptionsRef}
            style={{
              position: "absolute",
              top: `${emojiPanelPosition.top}px`,
              left: `${leftPosition}px`,
              width: "100px",
              height: "50px",
              zIndex: 1000,
            }}
          >
            <ChatReact onReactSend={onReactSend} />
            <MessageOptions
              isRight={false}
              msg={msg}
              handleMessageCopy={handleMessageCopy}
              handleMessageReply={handleMessageReply}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default LeftBubble;
