import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import Header from '../../../Components/UserHeader';
import Footer from '../../../Components/Footer';
import { postValuebet, CREATE_CLUB_URL } from '../../../API-Handle/services/auth_curd'
import { successNotification } from '../../../Components/CustComp/PPHAlert';
import ValuebetContext from '../../../context/Valuebet/ValuebetContext';
import withRouter from '../../../Components/CustComp/withRouter'
import CustomDropdown from '../../../Components/CustInputs/CustomDropdown';
import { LINES } from '../../../constant';
import { refinHeading } from '../../../Utils/comman';

class Club extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clubName: "",
      sportsbook: null,
      input_errors:
      {
        clubName: "",
        sportsbook: "",
      },
    };
    this.submitHandler = this.submitHandler.bind(this);
  }

  static contextType = ValuebetContext;

  componentDidMount() {
    this.context.getUserInfo()
  }

  changeClubName = (e) => {
    this.setState({ 'clubName': refinHeading(e.target.value) })
  }

  changeLine = (e) => {
    this.setState({ sportsbook: e })
  }

  async submitHandler() {

    let clubName_error = '';
    let sportsbook_error = '';

    clubName_error =
      this.state.clubName.length === 0
        ? 'Please enter Club Name.'
        : this.state.clubName.length > 17
          ? 'Club name has max 17 characters limit.'
          : '';

    sportsbook_error =
      !this.state.sportsbook
        ? 'Please select any sportsbook.'
        : '';

    this.setState({ input_errors: { clubName: clubName_error, sportsbook: sportsbook_error } })


    if (clubName_error === '' && sportsbook_error === '') {
      let data = { name: this.state.clubName, sportsbook: this.state.sportsbook?.value };
      let res = await postValuebet(CREATE_CLUB_URL, data)
      if (res) {
        successNotification(res.message)
        this.props.navigate('/dashboard');
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <div className='fill-view'>
          <div className='cardwapper container-fluid'>
            <div className="wrapper-page cardcontainer">
              <div className="content-page">
                <div className="content">
                  <Container fluid className='overridemargintop'>
                    <Row>
                      <Col className='text-center clubbanner'>
                        <h4 className='clubbanner-text m-0'>Create Club</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card className='crtmclubcard'>
                          <CardBody>
                            <form className='formcntrclub'>
                              <div className="field">
                                <label className='text-light font-size-14px' htmlFor="clubName">Club Name</label>
                                <input className='form-control bg-dark text-white border-secondary' type="text" name="clubName"
                                  onChange={this.changeClubName}
                                  value={this.state.clubName}
                                  id="clubName" placeholder=""
                                  required />
                              </div>
                              {this.state.input_errors.clubName ? <div className='inputerrors'> {this.state.input_errors.clubName} </div> : null}

                              <div className="field mt-4">
                                <label className='text-light font-size-14px' htmlFor="sportsbook">Select Sportsbook Odds</label>
                                <CustomDropdown
                                  placeholder="Select"
                                  options={LINES}
                                  value={this.state.sportsbook}
                                  onChange={this.changeLine}
                                  isDisabled={false}
                                  isMD={true}
                                />
                              </div>
                              {this.state.input_errors.sportsbook ? <div className='inputerrors'> {this.state.input_errors.sportsbook} </div> : null}
                              <Row className="form-group m-t-20">
                                <Col md="12" className="text-center">
                                  <button onClick={this.submitHandler} className="signbtn" type="button">Create Club <i className="fa fa-arrow-right submticon" aria-hidden="true"></i> </button>
                                </Col>
                              </Row>
                            </form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(Club);