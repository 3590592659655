import React, { useState } from 'react'
import Switch from "react-switch";
import { MEMBER_STATUS, postValuebet } from '../../../../../API-Handle/services/auth_curd';
import { successNotification } from '../../../../../Components/CustComp/PPHAlert';

function MemberStatus({row, activeClub}) {
  const [loading, setLoading] = useState(false);

  const onMemberStatus = async () => {
    setLoading(true)
    const body = {
      club_id: activeClub.id,
      user_id: row.id,
    };
    const response = await postValuebet(MEMBER_STATUS, body)
    if(response){
      row.details.is_active = !row.details.is_active;
      successNotification(response.message)
      setLoading(false)
    }
  }

  const toggleOnLimitUpdate = () => {
    setLoading(true);
    row.details.is_active = true;
    setTimeout(()=>{
      setLoading(false);
    }, 100);
  }

  return (
    <div>
        <div id={`member_status_${row.id}`} onClick={toggleOnLimitUpdate}></div>
        <label htmlFor="material-switch" className="my-0">
            <Switch
              id={`member_status`}
              checked={row.details.is_active}
              onChange={onMemberStatus}
              onColor="#008000"
              onHandleColor="#1e5e1efa"
              offColor="#ffac8b"
              offHandleColor="#e03f00"
              handleDiameter={16}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={12}
              width={30}
              className="react-switch"
              disabled={loading}
            />
        </label>
    </div>
  )
}

export default MemberStatus