import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setClubAdminStatus, toggleMessanger, collapsePersonalChannel } from '../../../../../redux/services/chatServices';
import { errorNotification } from '../../../../../Components/CustComp/PPHAlert';
import { useNavigate } from 'react-router';
import ValuebetContext from '../../../../../context/Valuebet/ValuebetContext';

function MessageAction({ row, activeClub }) {
  const dispatch = useDispatch();
  const { isClubChatOpen } = useSelector(state => state.clubChat);
  const { userDetails } = useContext(ValuebetContext);
  const navigate = useNavigate();

  const openChat = () => {
    if (row.id === userDetails.id) return;
    if (activeClub.level_details.messaging) {
      dispatch(toggleMessanger(activeClub, isClubChatOpen));
      dispatch(setClubAdminStatus(true));
      dispatch(collapsePersonalChannel(false, activeClub.id));
      navigate('?type=MEM_CHAT&member_id=' + row.id)
    } else {
      errorNotification("Upgrade Club Level to Access")
    }
  }

  return (
    <div onClick={openChat} className='ms-3' role='button'>
      <i className="fa fa-comments font-size-18px" aria-hidden="true"></i>
    </div>
  )
}

export default MessageAction
