import React from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessTime from '@mui/icons-material/AccessTime';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import moment from 'moment'
import { APP_OFFSET } from '../../constant';
import { getReadablePeriod } from '../../Utils/sportsbook';

export default function OddList({ data }) {
    return (
        data.map((item, indx) => {
            return (
                <div key={indx}>
                    <div className='bet-options py-2'>
                        <div>
                            {item.bet.is_correct === "PENDING" && <AccessTime className='icon-bet-pending' />}
                            {item.bet.is_correct === "YES" && <CheckCircleOutlineIcon className='icon-bet-yes' />}
                            {item.bet.is_correct === "NO" && <CancelIcon className='icon-bet-no' />}
                            {item.bet.is_correct === "TIE" && <RemoveCircle className='icon-bet-tie' />}
                            {(indx + 1) !== data.length && <div className='bet-line' />}
                        </div>
                        <div className='bet-options-box'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <strong className='bet-option-title'>{item.bet.market_name}</strong> <span> - {getReadablePeriod(item.bet.period_name)}</span>
                                    <br/>
                                    <small className='bet-option-subtitle'>{item.bet.name}</small>
                                </div>
                                <div>
                                    {item.bet.selected_odd_money !== "0" && <strong className='bet-option-title'>{item.bet.selected_odd_money > 0 ? `+${item.bet.selected_odd_money}` : item.bet.selected_odd_money}</strong>}
                                </div>
                            </div>
                            
                            <label className='font-size-10px fw-bolder text-secondary mb-1 mt-3'>{`${item.match?.event_name?.split(' - ')[0]} - ${moment.utc(item.match?.event_date).utcOffset(APP_OFFSET).format('LLL')} PST (${item.match.venue_name}, ${item.match.venue_location})`?.toUpperCase()}</label>
                            <div className='history-match-container'>
                                <div>
                                    <div className='history-team-list'>
                                        <div className='font-size-12px'>{item.match.away_team_name}</div>
                                        <div className='font-size-12px'>{item.match.home_team_name}</div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end align-items-center'>
                                    <div className='history-team-list'>
                                        <strong className='bet-title-his'>{item.match.score_away}</strong>
                                        <strong className='bet-title-his'>{item.match.score_home}</strong>
                                    </div>
                                    <div className='horiz-line' />
                                    <div className='light-text-center'>
                                        <p className='horiz-right-text'>{item.match.event_status_detail.includes("EDT") ? moment.utc(item.match?.event_date).utcOffset(APP_OFFSET).format('hh:mm A') + " PST" : item.match.event_status_detail}</p>
                                    </div>
                                </div>
                            </div>
                            {(indx + 1) !== data.length && <div className='horizantal-line'></div>}
                        </div>
                    </div>
                </div>
            )
        })
    )
}
