import React, { useEffect, useRef, useState } from "react";
import ValuebetContext from './ValuebetContext'
import { getValuebet, GET_CLUBS_URL, GET_MY_DETAILS, GET_SPORTS, GET_EVENTS, NOTIFICATIONS_URL } from "../../API-Handle/services/auth_curd";
import WebSocketService from '../../API-Handle/services/WebSocket';
import { OFFLINE_ODD } from '../../constant';
import BlockLoader from "../../Components/CustLoader/BlockLoader";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { clubchatAction } from "../../redux/features/clubchatSlice";
import { updateSubscription } from "../../firebase/firebaseConfig";
import { chatReconnection } from "../../redux/services/chatServices";
import { getMultiDecimalOdd, getSportLimits } from "../../Utils/sportsbook";


export default function ValuebetState(props) {
    const dispatch = useDispatch();
    const { isClubChatOpen, isPulicChannel, isPersonalChannel, selectedClub, selectedChannelID } = useSelector(state => state.clubChat)
    const { selectedTeaserPoints } = useSelector((state) => state.sportBook)
    const location = useLocation();
    const publicConnection = useRef(null);
    const privateConnection = useRef(null);
    const chatConnection = useRef(null);


    // ==================== User Management States Start ===========================

    const [userDetails, setUserDetails] = useState(null) // Profile State
    const [notiData, setNotiData] = useState([])  // Notifications State
    const [clubData, setClubData] = useState(null)  // Clubs State
    const [activeClub, setActiveClub] = useState(null)  // Current Club State
    const [clubApplicants, setClubApplicants] = useState(null)  // Current Club State

    // Refs to store the latest state values
    const activeClubRef = useRef(activeClub);
    const userDetailsRef = useRef(userDetails);
    const isClubChatOpenRef = useRef(isClubChatOpen);
    const isPulicChannelRef = useRef(isPulicChannel);
    const isPersonalChannelRef = useRef(isPersonalChannel);
    const selectedClubRef = useRef(selectedClub);
    const selectedChannelIDRef = useRef(selectedChannelID);
    const selectedTeaserPointsRef=useRef(selectedTeaserPoints);
    
    // ==================== User Management States End =============================
    
    
    
    // ================== Sportsbook Management States Start =======================
    
    const [rootLoader, setRootLoader] = useState(false); // root loader
    const [isFilterVisible, setIsFilterVisible] = useState(true); // UI Visiblity State
    const [isBetVisible, setIsBetVisible] = useState(false);  // Bet View State
    const [sports, setSports] = useState(null);  // Sports/League details
    const [leagueEvents, setLeagueEvents] = useState([]);  // League wise all Matches
    const [betDetails, setBetDetails] = useState({ bet_info: { bet_type: "Straight" }, bets: [] });  // Bets list with details
    
    // Refs to store the latest state values
    const sportsRef = useRef(sports);
    
    // =================== Sportsbook Management States End ========================

    useEffect(() => {
        // TODO: Rename publicConnection connection to SportsPrivateConnection
        // Put Inside Authanticate section after privateConnection
        // Authantication require but Sport data will be sent on same group by backend after user authantication.
        // URL eg:- 1) Private & User Specific -> "ws://server.valuebet.appp/ws/valuebet?token=xyz&context=USER"
        // URL eg:- 2) Private & Sport Specific -> "ws://server.valuebet.appp/ws/valuebet?token=xyz&context=SPORT"
        // URL eg:- 3) Publuc & Any Task -> "ws://server.valuebet.appp/ws/valuebet?context=PUBliC" // Not use for now
        // ---------------------------------------------------------------------------------------------------------
        if (!publicConnection?.current && (location.pathname === "/sportbook/sports")) {
            createPublicWebsocketInstances();
        } else if (publicConnection?.current) {
            publicConnection.current.close()
            publicConnection.current = null;
        }

        // ---------------------------------------------------------------------------------------------------------

        if (localStorage.getItem("authToken")) {
            if (!userDetails) {
                getUserInfo()
                getAllNotifications()
            }
            if (!privateConnection?.current) {

                createPrivateWebsocketInstances()
            }
            if (!chatConnection?.current) {
                createChatWebSocketInstances()
            }
        } else {
            if (privateConnection?.current) {
                publicConnection.current.close()
                privateConnection.current = null;
            }
            if (chatConnection?.current) {
                chatConnection.current.close()
                chatConnection.current = null;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    useEffect(() => {
        sportsRef.current = sports;
        activeClubRef.current = activeClub;
        userDetailsRef.current = userDetails;
        isClubChatOpenRef.current = isClubChatOpen;
        isPulicChannelRef.current = isPulicChannel;
        isPersonalChannelRef.current = isPersonalChannel;
        selectedClubRef.current = selectedClub;
        selectedChannelIDRef.current = selectedChannelID;
        selectedTeaserPointsRef.current = selectedTeaserPoints;
    }, [sports, activeClub, userDetails, isClubChatOpen, isPulicChannel, isPersonalChannel, selectedClub, selectedChannelID, selectedTeaserPoints])


    // ==================== User Management Functions Start ===========================

    const createPublicWebsocketInstances = () => {
        publicConnection.current = new WebSocketService('public');
        publicConnection.current.connect()
        publicConnection.current.addCallbacks({
            'update_odd_signal': updateOdd,
            'delete_odd_signal': removeOdd,
            'update_match_signal': updateMatch,
        });
    }

    const createPrivateWebsocketInstances = () => {
        privateConnection.current = new WebSocketService('private');
        privateConnection.current.connect()
        privateConnection.current.addCallbacks({
            'new_notification': setNewNotification,
            'remove_notification': deleteNotification,
            'new_request': setNewRequest,
            'remove_request': deleteRequest,
            'channel_member_changed': reconnectChatSocket,
        });
    }

    const createChatWebSocketInstances = () => {
        chatConnection.current = new WebSocketService('chat');
        chatConnection.current.connect()
        chatConnection.current.addCallbacks({
            'message_added': setNewChatMessage,
            'react_added': setNewReact,
            'react_updated': setUpdateReact,
            'react_removed': setRemoveReact,
            'message_removed': removeMessage,
        });
    }

    // Get User Information
    const getUserInfo = async () => {
        let res = await getValuebet(GET_MY_DETAILS);
        if (res) {
            setUserDetails(res)
            if (res.topic_changes) {
                updateSubscription(true)
            }
        }
    }

    // Get Clubs Functions
    const fetchClub = async () => {
        let res = await getValuebet(`${GET_CLUBS_URL}`)
        if (res) setClubData(res);
    }

    // Get Single Club Details by id
    const fetchSingleClub = async (club_id) => {
        let res = await getValuebet(`${GET_CLUBS_URL}?club_id=${club_id}`)
        if (res) {
            if (clubData) {
                setClubData((prev) => {
                    prev.forEach((club, index) => {
                        if (club.id === res.id) {
                            prev[index] = res;
                        }
                    });
                    return prev;
                })
            }
            setActiveClub(res);
        }
    }

    // Get All User Notifications
    const getAllNotifications = async () => {
        let notifications = await getValuebet(NOTIFICATIONS_URL);
        if (notifications) {
            setNotiData(notifications)
        }
    }

    // Adding New Notification in Notification Bar
    const setNewNotification = async (data) => {
        setNotiData((prevNoti) => {
            let current_noti = JSON.parse(JSON.stringify(prevNoti));
            current_noti.unshift(data)
            return current_noti
        })
    }

    // Removing Notification in Notification Bar
    const deleteNotification = async (data) => {
        setNotiData((prevNoti) => {
            let current_noti = JSON.parse(JSON.stringify(prevNoti));
            let new_noti = current_noti.filter((itm) => itm.id !== data.id)
            return new_noti
        })
    }

    // Reflecting Club Requestes & Adding New Request
    const setNewRequest = async (data) => {

        setClubData((prevClubData) => {
            let club_data = null;
            if (prevClubData) {
                club_data = JSON.parse(JSON.stringify(prevClubData))
                for (let club_details of club_data) {
                    if (club_details.id === data.club) {
                        club_details.has_applicants = true;
                        break;
                    }
                }
            }
            return club_data
        })

        if (data.club === activeClubRef.current.id) {
            setClubApplicants((prev) => {
                let new_applicants = null;
                if (prev) {
                    new_applicants = [...prev, data]
                }
                return new_applicants;
            })
        }
    }

    // Removing Club memebers' requests
    const deleteRequest = async (data) => {
        if (data.club === activeClubRef.current.id) {
            setClubApplicants((prev) => {
                let new_applicants = null;
                if (prev) new_applicants = prev.filter((itm) => itm.id !== data.id)
                return new_applicants;
            })
        }
    }

    // reconnect chat socket if member changged.
    const reconnectChatSocket = async (data) => {
        chatConnection.current.reconnect();
        if (data.club === selectedClubRef.current?.id) dispatch(chatReconnection(
            selectedClubRef.current?.id,
            isClubChatOpenRef.current,
            isPersonalChannelRef.current,
            isPulicChannelRef.current,
            data
        ))
        if (userDetailsRef) updateSubscription(true);
    }

    const removeMessage = async (data) => {
        dispatch(clubchatAction.unsendChannelMessage({ data: data }));
    }

    const setNewChatMessage = async (data) => {
        dispatch(clubchatAction.appendNewMessage(data));
        dispatch(clubchatAction.updateChannelInfo({ data: data }))
        if (selectedChannelIDRef.current !== data.channel) {
            setClubData((prevClubData) => {
                let club_data = null;
                if (prevClubData) {
                    club_data = JSON.parse(JSON.stringify(prevClubData))
                    for (let club_details of club_data) {
                        if (club_details.id === data.club) {
                            club_details.has_message = true;
                            break;
                        }
                    }
                }
                return club_data
            })
        }
    }

    const setNewReact = async (data) => {
        const updatedData = { ...data, action_type: "Add" }
        dispatch(clubchatAction.setCurrentReactedEmoji({ data: updatedData }));
    }

    const setUpdateReact = async (data) => {
        const updatedData = { ...data, action_type: "Update" }
        dispatch(clubchatAction.setCurrentReactedEmoji({ data: updatedData }));
    }

    const setRemoveReact = async (data) => {
        const updatedData = { ...data, action_type: "Remove" }
        dispatch(clubchatAction.setCurrentReactedEmoji({ data: updatedData }));
    }

    const accountLogout = async () => {
        setRootLoader(true);
        await updateSubscription(false)
        localStorage.removeItem("authToken");
        window.location = '/';
    }

    // ==================== User Management Functions End =============================


    // ================== Sportsbook Management Functions Start =======================

    // UI Visiblity Function
    const onFilterClick = () => {
        setIsFilterVisible(!isFilterVisible)
    }

    // Get Sports
    const getSports = async (club_id, teaser_id=null) => {
        // setSports(null)
        let url = GET_SPORTS + club_id
        if (teaser_id) url = url + `?teaser_id=${teaser_id}`;
        let res = await getValuebet(url);
        if (res) {
            setSports(res);
        }
    }

    // Get Event
    const getEvent = async (sport_id, league_id) => {
        setRootLoader(true);
        let sport_name = sports.filter((item) => item.id === sport_id)[0]['name'];
        let league_name = sports.filter((item) => item.id === sport_id)[0]['leagues'].filter((lg) => lg.id === league_id)[0]['league_name'];
        let league_info = {
            sport_id: sport_id,
            league_id: league_id,
            sport_name: sport_name,
            league_name: league_name,
            title: sport_name + " - " + league_name
        }
        let events = await getValuebet(`${GET_EVENTS + activeClub.id}/${league_id}`);
        if (events) {
            league_info['events'] = events
            setLeagueEvents([...leagueEvents, league_info])
        }
        setRootLoader(false);
    }

    const updateBetOptions = (odd) => {
        setLeagueEvents((prevEvt) => {
            let current_evt = JSON.parse(JSON.stringify(prevEvt));
            for (let evt of current_evt) {
                if (evt.league_id === odd.league) {
                    for (let match of evt.events) {
                        if (match.id === odd.match) {
                            try {
                                match.odds[odd.line_common_id][odd.period_name][odd.market_name][odd.odd_name].odd_points = odd.odd_points
                                match.odds[odd.line_common_id][odd.period_name][odd.market_name][odd.odd_name].odd_money = odd.odd_money
                                match.odds[odd.line_common_id][odd.period_name][odd.market_name][odd.odd_name].is_updated = true
                            } catch (err) {
                                console.log(err)
                            }
                            break;
                        }
                    }
                    break;
                }
            }
            return current_evt
        })
    }

    const updateSelectedBets = (odd) => {
        setBetDetails((prevBets) => {
            let current_bets = JSON.parse(JSON.stringify(prevBets));
            current_bets.bets.forEach(bet => {
                if (bet.id === odd.id) {
                    // Fetching Period name
                    let period_name = '';
                    if (odd.period_name === 'period_full_game') {
                        period_name = "Game"
                    } else if (odd.period_name === 'period_live_full_game') {
                        period_name = "Game(Live)"
                    }

                    // Fetching odd_points, odd_money, sub_title, market_name
                    let market_name = '';
                    let odd_points = '0';
                    let odd_money = odd.odd_money > 0 ? `+${odd.odd_money}` : odd.odd_money;
                    let sub_title = '';
                    if (odd.market_name === 'moneyline') {
                        market_name = "Moneyline";
                        sub_title = `${market_name} ${odd_money} for ${period_name}`
                    } else if (odd.market_name === 'spread') {
                        market_name = "Spread";
                        if(current_bets.bet_info.bet_type !== "Teaser"){    
                            odd_points = odd.odd_points > 0 ? `+${odd.odd_points}` : odd.odd_points === "0" ? `pk` : odd.odd_points;
                            sub_title = `${market_name} ${odd_points} ${odd_money} for ${period_name}`
                        }
                        else{
                            odd_points = parseFloat(odd.odd_points) + selectedTeaserPointsRef.current.teaser_points;
                            odd_points = odd_points > 0 ? `+${odd_points}` : odd_points === "0" ? `pk` : odd_points;
                            sub_title = `${market_name} ${odd_points} for ${period_name}`   
                        }

                    } else if (odd.market_name === 'total') {
                        market_name = "Total";
                        if(current_bets.bet_info.bet_type !== "Teaser"){    
                            odd_points = odd.odd_points;
                            sub_title = `${market_name}(${odd.odd_name}) ${odd_points} ${odd_money} for ${period_name}`
                        }
                        else{
                            odd_points = parseFloat(odd_points) + selectedTeaserPointsRef.current.teaser_points;
                            sub_title = `${market_name}(${odd.odd_name}) ${odd_points} for ${period_name}`
                        }
                    }

                    // Set new values for each bet 
                    bet.odd_points = odd.odd_points;
                    bet.odd_money = odd.odd_money;
                    bet.sub_title = sub_title;

                    // Set new risk/win for bets
                    if (current_bets?.bet_info?.bet_type === "Straight") {
                        let risk_val = '';
                        let win_val = '';
                        let min_limit = 25;
                        let max_limit = getStraightBetMaxLimit(bet);
                        if (odd.odd_money !== OFFLINE_ODD) {
                            if (odd.odd_money > 0) {
                                if (bet?.init_risk) {
                                    risk_val = Math.abs(bet.init_risk);
                                    if (risk_val > max_limit) risk_val = max_limit;
                                    if (risk_val < min_limit) risk_val = min_limit;
                                    win_val = ((1 + (odd.odd_money / 100)) * risk_val) - risk_val;
                                    win_val = Number(win_val.toFixed(2));
                                }
                            } else {
                                if (bet?.init_win) {
                                    win_val = Math.abs(bet.init_win);
                                    if (win_val > max_limit) win_val = max_limit;
                                    risk_val = win_val / ((1 - (100 / odd.odd_money)) - 1);
                                    risk_val = Number(risk_val.toFixed(2));
                                    if (risk_val < min_limit) {
                                        risk_val = min_limit;
                                        win_val = ((1 - (100 / odd.odd_money)) * risk_val) - risk_val;
                                        win_val = Number(win_val.toFixed(2));
                                    }
                                }
                            }
                        }
                        bet.to_win = win_val;
                        bet.risking = risk_val;

                    } else if (current_bets?.bet_info?.bet_type === "Parlay") {
                        let risk_val = '';
                        let win_val = '';
                        let min_limit = 25;
                        let max_limit = getMultiBetMaxLimit(current_bets.bets, current_bets?.bet_info?.bet_type);
                        let max_payout = getMultiBetMaxPayoutLimit(current_bets.bets);
                        let decimal_odd = getMultiDecimalOdd(current_bets.bets);
                        if (decimal_odd !== OFFLINE_ODD) {
                            if (decimal_odd >= 2) {
                                if (current_bets?.bet_info?.init_risk) {
                                    risk_val = Math.abs(current_bets.bet_info.init_risk);
                                    if (risk_val > max_limit) risk_val = max_limit;
                                    if (risk_val < min_limit) risk_val = min_limit;
                                    win_val = Number(((decimal_odd * risk_val) - risk_val).toFixed(2));
                                }
                            } else {
                                if (current_bets?.bet_info?.init_win) {
                                    win_val = Math.abs(current_bets.bet_info.init_win);
                                    if (win_val > max_limit) win_val = max_limit;
                                    risk_val = Number((win_val / (decimal_odd - 1)).toFixed(2));
                                    if (risk_val < min_limit) {
                                        risk_val = min_limit;
                                        win_val = Number(((decimal_odd * risk_val) - risk_val.toFixed(2)));
                                    }
                                }
                            }
                            if (win_val !== '') {
                                if (max_payout < win_val) {
                                    win_val = max_payout;
                                    risk_val = Number((win_val / (decimal_odd - 1)).toFixed(2));
                                    if (risk_val < min_limit) {
                                        risk_val = min_limit;
                                        win_val = Number(((decimal_odd * risk_val) - risk_val.toFixed(2)));
                                    }
                                }
                            }
                        }
                        current_bets.bet_info.to_win = win_val;
                        current_bets.bet_info.risking = risk_val;
                    }
                }
            });
            return current_bets;
        })
    }

    // Checking odd's value has under limit or not
    const manipulateOddValue = (odd) => {
        let limit_selecter = getSportLimits(sportsRef.current, odd.league_name, 'odd_limits')
        if (limit_selecter['is_activate']) {
            if (odd.odd_money < 0) {
                if (-(limit_selecter['neg_odd_limits']) >= odd.odd_money) {
                    odd.odd_money = OFFLINE_ODD
                }
            }
            if (odd.odd_money > 0) {
                if (limit_selecter['pos_odd_limits'] <= odd.odd_money) {
                    odd.odd_money = OFFLINE_ODD
                }
            }
        }
        return odd
    }

    // Updating Odds
    const updateOdd = async (actual_odd) => {
        if (actual_odd.line_common_id === activeClubRef.current?.sportsbook) {
            let odd = actual_odd;
            if (actual_odd.odd_money !== OFFLINE_ODD) odd = manipulateOddValue(actual_odd)
            updateBetOptions(odd);
            updateSelectedBets(odd);
        }
    }

    // Removing Odds
    const removeOdd = async (odd) => {
        if (odd.line_common_id === activeClubRef.current?.sportsbook) {
            updateBetOptions(odd)
            updateSelectedBets(odd);
        }
    }

    // Updating Match & Score
    const updateMatch = async (match) => {
        setLeagueEvents((prevEvt) => {
            let current_evt = JSON.parse(JSON.stringify(prevEvt));
            for (let evt of current_evt) {
                if (evt.league_id === match.league) {
                    for (let cmatch of evt.events) {
                        if (cmatch.id === match.id) {
                            try {
                                cmatch.event_status = match.event_status
                                cmatch.event_status_detail = match.event_status_detail
                                cmatch.game_clock = match.game_clock
                                cmatch.display_clock = match.display_clock
                                if (match.score_away !== null) {
                                    cmatch.event_score.score_away = match.score_away
                                }
                                if (match.score_home !== null) {
                                    cmatch.event_score.score_home = match.score_home
                                }
                            } catch (err) {
                                console.log(err)
                            }
                            break;
                        }
                    }
                    break;
                }
            }
            return current_evt
        })
    }

    // Remove Selected Bet
    const removeSelectedBet = (value) => {
        let new_bets = betDetails?.bets.filter((bet) => bet.id !== value.id);
        setBetDetails({ ...betDetails, bets: new_bets })
    }

    // Remove All Bets
    const resetSportBook = async () => {
        setRootLoader(false)
        setIsFilterVisible(true)
        setIsBetVisible(false)
        setLeagueEvents([])
        setBetDetails({ bet_info: { bet_type: "Straight" }, bets: [] })
    }

    const resetTeaserOnPointChange = () => {
        setSports([])
        setLeagueEvents([]);
        setBetDetails({ bet_info: { bet_type: betDetails.bet_info.bet_type }, bets: [] })
    }

    const getStraightBetMaxLimit = (bet) => {
        let limit_name = ''
        if (bet.period === "period_live_full_game") {
            limit_name = 'max_bet_live'
        } else {
            limit_name = 'max_bet_all'
        }
        let max_limit = 0;
        let limit_selecter = getSportLimits(sportsRef.current, bet.league_name, 'bet_limits')
        if(limit_selecter) max_limit = limit_selecter[limit_name];
        return max_limit
    }

    const getMultiBetMaxLimit = (bets, bet_type) => {
        let max_limit = 0
        for (let bet of bets) {
            let limit_name = ''
            if (bet.period === "period_live_full_game") {
                limit_name = bet_type === "Parlay" ? 'max_parlay_live' : "max_bet_live";
            } else {
                limit_name = bet_type === "Parlay" ? 'max_parlay_all' : "max_bet_all";
            }

            let limit_selecter = getSportLimits(sportsRef.current, bet.league_name, 'bet_limits')
            if(limit_selecter) {
                let temp_max_limit = limit_selecter[limit_name];
                if ((!max_limit) || (max_limit > temp_max_limit)) {
                    max_limit = temp_max_limit;
                }
            }
        }
        return max_limit
    }

    const getMultiBetMaxPayoutLimit = (bets) => {
        let max_limit = 0
        for (let bet of bets) {
            let limit_selecter = getSportLimits(sportsRef.current, bet.league_name, 'bet_limits')
            if(limit_selecter) {
                let temp_max_limit = limit_selecter['max_parlay_payout'];
                if ((!max_limit) || (max_limit > temp_max_limit)) {
                    max_limit = temp_max_limit;
                }
            }
        }
        return max_limit
    }

    // =================== Sportsbook Management Functions End ========================

    return (
        <ValuebetContext.Provider
            value={{
                // User Management Attirbutes
                clubData, setClubData, fetchClub,
                clubApplicants, setClubApplicants,
                activeClub, setActiveClub, fetchSingleClub,
                notiData, setNotiData, getAllNotifications,
                userDetails, setUserDetails, getUserInfo,
                chatConnection, accountLogout,

                // Sportsbook Attirbutes
                rootLoader, setRootLoader, resetSportBook, resetTeaserOnPointChange,

                isFilterVisible, setIsFilterVisible, onFilterClick,
                isBetVisible, setIsBetVisible,

                sports, getSports,
                leagueEvents, getEvent, setLeagueEvents,
                betDetails, setBetDetails, removeSelectedBet,

                getStraightBetMaxLimit, getMultiBetMaxLimit, getMultiBetMaxPayoutLimit
            }}>
            {rootLoader && <BlockLoader />}
            {props.children}
        </ValuebetContext.Provider>
    )
}