import React, { useEffect, useState } from 'react'
import { getValuebet, GET_ALL_CLUB_MEMBER } from '../../../../../../API-Handle/services/auth_curd';
import PlayerRow from './PlayerRow';

export default function Index({ club }) {
    const [players, setPlayers] = useState([])

    useEffect(() => {
        getPlayerBetLimits();
    // eslint-disable-next-line
    }, [])

    const getPlayerBetLimits = async () => {
        let request_url = `${GET_ALL_CLUB_MEMBER}${club.id}`;
        let players = await getValuebet(request_url);
        setPlayers(players)
    }

    const getHeight = () => {
        return window.innerHeight - (club?.level_details?.sport_limits ? 240 : 260);
    }

    return (
        <div className="tab-content">
            <div className="tab-pane fade active show min-vh-50" id="player-bet-limits">
            {!club?.level_details?.sport_limits && <div className='text-warning py-1 px-2 mb-1 bg-dark rounded fw-bold'>Upgrade Club for Access</div>}
                <div className="overflow-x overflow-y" style={{ maxHeight: getHeight()}}>
                    <table className={`table table-custom-dark ${!club?.level_details?.sport_limits && "section-disable"}`}>
                        <thead>
                            <tr>
                                <th scope="col" className="align-top">Player</th>
                                <th scope="col" className="align-top">Limited</th>
                                <th scope="col" className="align-top">More</th>
                            </tr>
                        </thead>
                        <tbody>
                            {players.map((item, index) => {
                                return (
                                    <PlayerRow key={index} item={item} club={club} />
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
