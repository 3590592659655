import React, { useContext } from 'react'
import {
    Button,
} from "reactstrap";
import { UPGRADE_CLUB_URL, postValuebet } from '../../../../../../../API-Handle/services/auth_curd';
import { successNotification } from '../../../../../../../Components/CustComp/PPHAlert';
import ValuebetContext from '../../../../../../../context/Valuebet/ValuebetContext';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { DefaultConfirm } from '../../../../../../../Components/SweetAlerts/DefaultConfirm';

export default function Plan({ item, toggleLevelModal, clubInfo }) {
    const {fetchSingleClub, getUserInfo, rootLoader, setRootLoader } = useContext(ValuebetContext)

    const getNote = () => {
        const {level, cost, days, remaining_days } = clubInfo?.level_details;
        let notes = '';
        if (level === item.level){
            notes = `Do you want to get additional ${item.days} Days for Level-${item.level} by spending ${item.cost} Coins?`
        } else if (level !== 1 && remaining_days){
            const prev_remaining = (cost / days) * remaining_days;
            const from_wallet = item.cost - prev_remaining;
            notes = `<p class="font-size-14px text-warning">Deduction Details</p> <p class="font-size-14px text-success fst-italic">${prev_remaining.toFixed(2)} (Existing Level Credit) + ${from_wallet.toFixed(2)} (Deduct From Wallet) <br/><br/>= ${item.cost}(Total Cost)</p> Do you want to upgrade club (${clubInfo.name}) into Level-${item.level} by spending ${from_wallet.toFixed(2)} Coins?`
        }else{
            notes = `Do you want to upgrade club (${clubInfo.name}) into Level-${item.level} by spending ${item.cost} Coins?`
        }
        return notes;
    }

    const upgradeClubLevel = async () => {
        let message = getNote();
        let confirmation = await DefaultConfirm("Upgrade Club", message)
        if (!confirmation) return
        setRootLoader(true)
        let data = {
            level_id: item.id,
            club_id: clubInfo.id
        }
        let res = await postValuebet(UPGRADE_CLUB_URL, data)
        if (res) {
            successNotification(res.message)
            fetchSingleClub(clubInfo.id)
            getUserInfo()
            toggleLevelModal()
        }
        setRootLoader(false)
    }

    return (
        <tr>
            <td className='align-middle'>{item.level}</td>
            <td className='align-middle'>{item.max_members}</td>
            <td className='align-middle'>{item.max_agents}</td>
            <td className='align-middle'>{item.odds_selection ? <CheckCircleOutlineIcon className='icon-bet-yes' /> : <CancelIcon className='icon-bet-no' />}</td>
            <td className='align-middle'>{item.sport_limits ? <CheckCircleOutlineIcon className='icon-bet-yes' /> : <CancelIcon className='icon-bet-no' />}</td>
            <td className='align-middle'>{item.messaging ? <CheckCircleOutlineIcon className='icon-bet-yes' /> : <CancelIcon className='icon-bet-no' />}</td>
            <td className='align-middle'>{item.leaderboard ? <CheckCircleOutlineIcon className='icon-bet-yes' /> : <CancelIcon className='icon-bet-no' />}</td>
            <td className='align-middle'>{item.days}</td>
            <td className='align-middle text-nowrap'>{item.cost} Coins</td>
            <td className='align-middle'>
                <Button
                    onClick={upgradeClubLevel}
                    disabled={rootLoader || item.level < clubInfo?.level_details?.level || item.cost === 0}
                    color='success'
                    className={item.cost === 0 ? 'text-info btn-sm' : 'btn-sm'} >
                       {item.cost === 0 ? <span>&nbsp;&nbsp;&nbsp;&nbsp;Free&nbsp;&nbsp;&nbsp;&nbsp;</span> : "Upgrade"}
                </Button>
            </td>
        </tr>
    )
}
