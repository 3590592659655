import React, { useContext, useEffect, useState } from 'react'
// import { Row } from 'reactstrap'
import LeagueCard from './LeagueCard';
import SportsButton from './SportsButton';
// import Customdropdown from './CustomDropdown';
import ValuebetContext from '../../../../context/Valuebet/ValuebetContext';


export default function Index() {
    const {
        isFilterVisible,
        isBetVisible,
        setIsBetVisible,
        leagueEvents,
        betDetails,
        sports
    } = useContext(ValuebetContext);

    const [height, setHeight] = useState(0);

    const onPlaceBetPress = () => {
        setIsBetVisible(true);
    }

    useEffect(() => {
        getHeight()
        window.addEventListener('resize', getHeight);
        return () => window.removeEventListener('resize', getHeight)
        // eslint-disable-next-line
    }, [leagueEvents, isBetVisible, isFilterVisible, betDetails])

    const getHeight = () => {
        let hgt = window.innerHeight - 74
        if (window.innerWidth < 992) {
            hgt -= 67;
            if (window.innerWidth < 767) hgt -= 35;
            if (betDetails?.bets?.length) hgt -= 42;
        }
        setHeight(hgt)
    }

    return (
        <div className={isFilterVisible !== true && isBetVisible !== true ? 'lague-box' : 'lague-box-inactive'}>
            <div className='league-filter-box'>
                {sports?.map((item) => {
                    return (
                        item.leagues?.map(league => (
                            league.has_odds && <SportsButton
                                item={item}
                                value={league}
                                key={league.id}
                            />
                        ))
                    )
                })}
                {/* <Customdropdown item={headDrop} /> */}

            </div>

            {leagueEvents.length ?
                <div style={{ height: height }} className='overflow-auto px-1'>
                    {leagueEvents.map(item => (
                        <LeagueCard item={item} key={item.league_id} />
                    ))}
                </div>
                : <div className="py-3 jumbotron-fluid bg-vb-dark text-secondary rounded">
                    <div className="container">
                        <h3 className="display-6">Are you <span className='vb-text-orange'>18+</span>?</h3>
                        <label className='valuebet-bg-color p-1 rounded text-light'>Choose sports and Start Betting!</label><br />
                        <label>This site is protected by reCAPTCHA and the Google.</label>
                    </div>
                </div>}
            {betDetails?.bets?.length ?
                <div className='bet-button-box'>
                    <button className='bet-btn' onClick={onPlaceBetPress}>
                        Bets ({betDetails?.bets?.length})
                    </button>
                </div> : null}
        </div>
    )
}
