import React from 'react'
import { Button } from 'reactstrap'
import { getValuebet, MEMBER_STOP_LIMITS, postValuebet, UPDATE_MEMBER_STOP_LIMIT } from '../../../../../../../API-Handle/services/auth_curd'
import { successNotification } from '../../../../../../../Components/CustComp/PPHAlert';

function StopLimit({ club, item }) {
  const [currentLimit, setcurrentLimit] = React.useState(0);
  const [lossStop, setLossStop] = React.useState("");
  const [winStop, setWinStop] = React.useState("");
  const [isLoading, setIsLoadig] = React.useState(false);

  React.useEffect(() => {
    fetchStopLimit();
  }, [])

  const fetchStopLimit = async () => {
    const url = MEMBER_STOP_LIMITS + `?club_id=${club.id}&user_id=${item.id}`;
    const response = await getValuebet(url);
    if (response) {
      setcurrentLimit(response.current_settlement)
      setLossStop(response.loss_stop)
      setWinStop(response.win_stop)
    }
  }

  const onSubmit = async () => {
    setIsLoadig(true)
    const body = {
      club_id: club.id,
      user_id: item.id,
    };
    if (winStop) body["win_stop"] = winStop;
    if (lossStop) body["loss_stop"] = lossStop;
    const response = await postValuebet(UPDATE_MEMBER_STOP_LIMIT, body)
    if (response) {
      setcurrentLimit(0);
      successNotification(response.message)
      document.getElementById(`member_status_${item.id}`).click();
    }
    setIsLoadig(false)
  }

  return (
    <div className={`col-md-6 rounded bg-dark pb-3 px-4 py-2 my-2 mt-3 ${!club?.level_details?.sport_limits && "section-disable"}`}>
      {!club?.level_details?.sport_limits && <div className='text-warning mb-2 bg-dark rounded fw-bold'>Upgrade Club for Access</div>}
      <div className="col-md-12 my-1">
        <label
          htmlFor="inputprofit"
          className="form-label text-white font-size-15px"
        >
          Net W/L : <span className='vb-text-orange font-size-18px'>{currentLimit}</span>
        </label>
      </div>
      <div className="col-md-12 my-1">
        <label
          htmlFor="inputloss"
          className="form-label text-white font-size-12px"
        >
          Loss Stop
        </label>
        <input
          type="number"
          className="form-control bg-dark text-white form-control-sm border-secondary"
          onChange={(e) => { setLossStop(parseInt(Math.abs(e.target.value))) }}
          value={lossStop || ""}
          min={0}
        />
      </div>
      <div className="col-md-12 my-1">
        <label
          htmlFor="inputprofit"
          className="form-label text-white font-size-12px"
        >
          Win Stop
        </label>
        <input
          type="number"
          className="form-control bg-dark text-white form-control-sm border-secondary"
          onChange={(e) => { setWinStop(parseInt(Math.abs(e.target.value))) }}
          value={winStop || ""}
          min={0}
        />
      </div>
      <div className="mb-btn col-md-2 text-center my-1">
        <Button
          className="w-100 sett-limit-btn"
          size="sm"
          color="danger"
          disabled={isLoading}
          onClick={onSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default StopLimit
