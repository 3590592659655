import React, { useContext, useState } from 'react'
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Settings from '../Settings';
import { postValuebet, LEAVE_CLUB_URL } from '../../../../../API-Handle/services/auth_curd';
import { useNavigate } from "react-router";
import ValuebetContext from '../../../../../context/Valuebet/ValuebetContext';
import { errorNotification, successNotification } from '../../../../../Components/CustComp/PPHAlert';
import { useDispatch, useSelector } from 'react-redux';
import { setClubAdminStatus, toggleMessanger } from '../../../../../redux/services/chatServices';
import { LockOutlined } from '@mui/icons-material';

function ClubAction({ item }) {
  const [settingModal, setSettingModal] = useState(false)
  const { fetchClub } = useContext(ValuebetContext)
  const { isClubChatOpen } = useSelector(state => state.clubChat)
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleClubSetting = () => {
    setSettingModal(!settingModal)
  };

  const removeClub = async () => {
    let data = { club_id: item.id };
    let res = await postValuebet(LEAVE_CLUB_URL, data);
    if (res) {
      successNotification(res.message);
      fetchClub();
    }
  };

  const goToApplications = () => {
    navigate(`/club/${item.id}/applicants`);
  }

  const goToMembers = () => {
    navigate(`/club/${item.id}/members`);
  }

  const goToAgents = () => {
    navigate(
      `/agents`,
      { state: { club_data: item } },
    );
  }

  const goToManager = () => {
    navigate(
      `/club-manager`,
      { state: { club_data: item } }
    );
  }

  const goToClubReport = () => {
    navigate(
      `/sportbook/club-reports`,
      { state: { club: item } }
    );
  }

  const goToYourReport = () => {
    navigate(
      "/player-report",
      { state: { item } }
    );
  }

  const openChat = (is_admin) => {
    if (item.level_details.messaging){
      dispatch(toggleMessanger(item, isClubChatOpen));
      dispatch(setClubAdminStatus(is_admin));
    }else{
      errorNotification("Upgrade Club Level to Access")
    }
  }

  return (
    <>
      {settingModal && <Settings item={item} isOpen={settingModal} toggleClubSetting={toggleClubSetting} />}
      <span className="dropdown">
        <UncontrolledButtonDropdown>
          <DropdownToggle color="none" className='btn-sm text-nowrap'>
            <i className="fa fa-ellipsis-v text-white" aria-hidden="true"></i> {item.has_applicants || item.has_message ? <sup className="font-size-15px text-danger"> ● </sup> : null}
          </DropdownToggle>
          <DropdownMenu end className='club-actions'>
            {(item.label === "Owner") | (item.label === "Manager") ? (
              <>
                <DropdownItem className='text-white' onClick={()=>openChat(true)} title='Upgrade Club Level to Access'>
                  <i className="fa fa-comments" aria-hidden="true"></i>{' '}
                  Message  {item.has_message ? <span className="text-danger"> ● </span> : null} {!item.level_details.messaging && <LockOutlined fontSize='small' className='icon-bet-no' />}
                </DropdownItem>
                <DropdownItem className='text-white' onClick={goToApplications}>
                  <i className="fa fa-user-plus" aria-hidden="true"></i>{' '}
                  Applicants {item.has_applicants ? <span className="text-danger"> ● </span> : null}
                </DropdownItem>
                <DropdownItem className='text-white' onClick={goToMembers}>
                  <i className="fa fa-users" aria-hidden="true"></i>{' '}
                  Members
                </DropdownItem>
                {/* <DropdownItem className='text-white' onClick={goToManager}>
                  <i className="fa fa-user-circle" aria-hidden="true"></i>{' '}
                  Managers
                </DropdownItem> */}
                <DropdownItem className='text-white' onClick={goToClubReport}>
                  <i className="fa fa-database" aria-hidden="true"></i>{' '}
                  Club Report
                </DropdownItem>
                {/* <DropdownItem className='text-white' onClick={goToAgents}>
                  <i className="fa fa-user-secret" aria-hidden="true"></i>{' '}
                  Agents
                </DropdownItem> */}
                {/* <DropdownItem className='text-white' onClick={goToYourReport}>
                  <i className="fa fa-database" aria-hidden="true"></i>{' '}
                  Report
                </DropdownItem> */}
                <DropdownItem className='text-white' onClick={() => toggleClubSetting()}>
                  <i className="fa fa-cog" aria-hidden="true"></i>{' '}
                  Club Settings
                </DropdownItem>
              </>
            ) :
              // (item.label == "Agent") | (item.label == "Sub-Agent") ?
              //   <>
              //     <DropdownItem className='text-white' onClick={goToApplications}>
              //       <i className="fa fa-user-plus" aria-hidden="true"></i>{' '}
              //       Applicants {item.has_applicants ? <span className="text-danger"> ● </span> : null}
              //     </DropdownItem>
              //     <DropdownItem className='text-white' onClick={goToMembers}>
              //       <i className="fa fa-users" aria-hidden="true"></i>{' '}
              //       Players
              //     </DropdownItem>
              //     {/* <DropdownItem className='text-white' onClick={goToAgents}>
              //       <i className="fa fa-user-secret" aria-hidden="true"></i>{' '}
              //       Agents
              //     </DropdownItem> */}
              //     <DropdownItem className='text-white' onClick={goToYourReport}>
              //       <i className="fa fa-database" aria-hidden="true"></i>{' '}
              //       Report
              //     </DropdownItem>
              //     <DropdownItem className='text-white' onClick={() => removeClub()}>
              //       <i className="fa fa-spinner"></i>{' '}
              //       Leave Club
              //     </DropdownItem>
              //   </> :
              <>
                {item.level_details.messaging &&
                <DropdownItem className='text-white' onClick={()=>openChat(false)}>
                  <i className="fa fa-comments" aria-hidden="true"></i>{' '}
                  Message {item.has_message ? <span className="text-danger"> ● </span> : null}
                </DropdownItem>}
                <DropdownItem className='text-white' onClick={() => removeClub()}>
                  <i className="fa fa-spinner"></i>{' '}
                  Leave Club
                </DropdownItem>
              </>
            }
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </span>
    </>
  )
}

export default React.memo(ClubAction)
