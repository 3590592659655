import React, { useContext } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import LockOutlined from '@mui/icons-material/LockOutlined';
import { errorNotification, successNotification } from '../../../../../../Components/CustComp/PPHAlert';
import { postValuebet, UPDATE_LEADERBOARD_STATUS_URL } from '../../../../../../API-Handle/services/auth_curd';
import ValuebetContext from '../../../../../../context/Valuebet/ValuebetContext';

const LeaderboardConf = ({ clubInfo }) => {
  const { fetchSingleClub } = useContext(ValuebetContext);

  const handleChange = async (event) => {
    let data = { lead_type: event.target.name }
    let res = await postValuebet(UPDATE_LEADERBOARD_STATUS_URL + clubInfo.id, data);
    if (res) {
      successNotification(res.massage);
      fetchSingleClub(clubInfo.id);
    }
  };

  const wantMessage = () => {
    if (!clubInfo.level_details.leaderboard) {
      errorNotification("Upgrade Club to Unlock!")
    }
  }

  return (
    <div onClick={wantMessage}>
      <label className='text-white'>Display Leaderboard:</label> {clubInfo.level_details.leaderboard ? "" : <LockOutlined fontSize='12' className='icon-bet-no mb-1' />}
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={clubInfo.leaderboard_status.daily}
              onChange={handleChange}
              name="Daily"
              disabled={!clubInfo.level_details.leaderboard}
            />
          }
          label="Daily"
          sx={{ color: "white" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={clubInfo.leaderboard_status.weekly}
              onChange={handleChange}
              name="Weekly"
              disabled={!clubInfo.level_details.leaderboard}
            />
          }
          label="Weekly"
          sx={{ color: "white" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={clubInfo.leaderboard_status.monthly}
              onChange={handleChange}
              name="Monthly"
              disabled={!clubInfo.level_details.leaderboard}
            />
          }
          label="Monthly"
          sx={{ color: "white" }}
        />
      </FormGroup>
    </div>
  );
};

export default LeaderboardConf;
