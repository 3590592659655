import React, { useState } from "react";
import FullLogo from "../../Static/UserManagement/images/common/vb_logo_white.png";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  NavbarText,
} from 'reactstrap';
import Login from "../../Pages/Essential/Auth/Login";
import Register from "../../Pages/Essential/Auth/Register";
import ForgotPassword from "../../Pages/Essential/Auth/ForgotPassword";
import UserGuide from "../PublicModals/UserGuide";
import ValuebetFAQ from "../PublicModals/ValuebetFAQ";
import ChallengeFAQ from "../PublicModals/ChallengeFAQ";
import RewardFAQ from "../PublicModals/RewardFAQ";
import { useNavigate } from "react-router-dom";

const PublicHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [menu, setMenu] = useState(false);
  const [faqModal, setFaqModal] = useState(false);
  const [faqChgModal, setFaqChgModal] = useState(false);
  const [faqRewardModal, setFaqRewardModal] = useState(false);
  const [guideModal, setGuideModal] = useState(false);
  const navigate = useNavigate();

  const toggle = () => setIsOpen(!isOpen);
  const toggleNav = () => setMenu(!menu);
  const faqToggle = () => setFaqModal(!faqModal);
  const faqChgToggle = () => setFaqChgModal(!faqChgModal);
  const faqRewardToggle = () => setFaqRewardModal(!faqRewardModal);
  const guideToggle = () => setGuideModal(!guideModal);

  return (
    <React.Fragment>
      <ValuebetFAQ isVisible={faqModal} modToggle={faqToggle} />
      <ChallengeFAQ isVisible={faqChgModal} modToggle={faqChgToggle} />
      <RewardFAQ isVisible={faqRewardModal} modToggle={faqRewardToggle} />
      <UserGuide isVisible={guideModal} modToggle={guideToggle} />
      <div>
        <Navbar
          className="cust-nav"
          color="dark"
          expand={"md"}
          container={"fluid"}
          dark
        >
          <NavbarBrand href="/">
            <span className="me-4">
              <img src={FullLogo} alt="Valuebet" className="full-logo" />
              {/* <sup className="text-warning"><small>Beta</small></sup> */}
            </span>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ms-auto py-3" navbar>
              <Dropdown
                isOpen={menu}
                toggle={toggleNav}
                className="list-item-nav d-inline"
              >
                <DropdownToggle className="list-item-nav me-0" tag={"span"} role='button' caret>
                  FAQ
                </DropdownToggle>
                <DropdownMenu>
                  <span className="dropdown-item" role='button' onClick={faqToggle}>
                    Valuebet FAQ
                  </span>
                  <span className="dropdown-item" role='button' id="challenge_faq_1" onClick={faqChgToggle}>
                    Challenge FAQ
                  </span>
                  <span className="dropdown-item" role='button' id="reward_faq_1" onClick={faqRewardToggle}>
                    Reward FAQ
                  </span>
                </DropdownMenu>
              </Dropdown>
              <span className="list-item-nav" role='button' onClick={() => navigate('/prices')}>
                PRICES
              </span>
                <span className="list-item-nav" role='button' onClick={guideToggle}>
                GUIDE
              </span>
              <span className="list-item-nav" role='button' onClick={() => navigate('/contact')}>
                CONTACT
              </span>
              {/* <a className="list-item-nav text-decoration-none" role='button' href="/blog">
                BLOG
              </a> */}
            </Nav>
            <NavbarText>
              <div className="d-flex py-2">
                <div className="me-1">
                  <Register title={"START"} className={"logsign"} />
                </div>
                <div>
                  <Login title={"LOGIN"} className={"logsign"} />
                  <ForgotPassword title={"Forgot"} className={"d-none"} />
                </div>
              </div>
            </NavbarText>
          </Collapse>
        </Navbar>
      </div>
    </React.Fragment>
  );
};

export default PublicHeader;

